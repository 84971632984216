import clsx from 'clsx';
import React from 'react';

import WagLogo from '../../assets/wag.svg';
import WagRewardsLogo from '../../assets/WagLogoRewards.svg';

type Props = {
  className?: string;
  variant?: 'default' | 'rewards';
}

export const Logo = ({
  className, variant = 'default',
}: Props) => (
  <a
    className={clsx(className)}
    href="https://wagwalking.com"
    aria-label="wag-logo-link"
  >
    {variant === 'rewards' ? (
      <img
        src={WagRewardsLogo}
        alt="wag-logo"
        className="w-[65px] h-[32px]"
      />
    ) : (
      <img
        src={WagLogo}
        alt="wag-logo"
        className="w-[65px] h-[32px]"
      />
    )}
  </a>
);
