import React, { useState } from 'react';
import { ChevronUpIcon, ChevronDownIcon } from 'lucide-react';
import {
  Root,
  Trigger,
  Content,
  CollapsibleProps,
} from '@radix-ui/react-collapsible';
import clsx from 'clsx';

import { footerMenuItems } from './data/menu-links';
import { CollapsibleFooterItem } from './CollapsibleFooterItem';

type Props = {
  rootProps?: CollapsibleProps;
  rootClassName?: string;
  item: typeof footerMenuItems[number];
}

export const CollapsibleFooterMenu = ({
  rootProps = {
    defaultOpen: true,
  },
  rootClassName,
  item,
}: Props) => {
  const [open, setOpen] = useState(false);

  return (
    <Root
      defaultOpen={rootProps.defaultOpen}
      className={clsx('w-full bg-transparent', rootClassName)}
      open={open}
      onOpenChange={setOpen}
    >
      <Trigger asChild>
        <button
          type="button"
          className="flex items-center justify-between font-[700] !py-4 w-full border-b border-b-slate-500 text-[14px]"
        >
          {item.title}
          {open
            ? (
              <ChevronUpIcon
                className="ml-1 h-4 w-4 text-white mr-1"
                strokeWidth={3}
              />
            )
            : (
              <ChevronDownIcon
                className="ml-1 h-4 w-4 text-white mr-1"
                strokeWidth={3}
              />
            )}
        </button>
      </Trigger>
      <Content className="data-[state=open]:animate-slideDown data-[state=closed]:animate-slideUp">
        {item.children.map(({ url, title }) => (
          <CollapsibleFooterItem
            key={title}
            title={title}
            url={url}
          />
        ))}
      </Content>
    </Root>
  );
};
