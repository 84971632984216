import facebookLogo from '../../../assets/facebook.svg';
import instagramLogo from '../../../assets/instagram.svg';
import twitterLogo from '../../../assets/twitter.svg';
import tiktokLogo from '../../../assets/tiktok.svg';

import facebookLogoHover from '../../../assets/facebook-hover.svg';
import instagramLogoHover from '../../../assets/instagram-hover.svg';
import twitterLogoHover from '../../../assets/twitter-hover.svg';
import tiktokLogoHover from '../../../assets/tiktok-hover.svg';

export const socialLinks = [
  {
    title: 'Facebook',
    link: 'https://www.facebook.com/WagWalking',
    icon: facebookLogo,
    iconHover: facebookLogoHover,
  },
  {
    title: 'Instagram',
    link: 'https://www.instagram.com/wag/',
    icon: instagramLogo,
    iconHover: instagramLogoHover,
  },
  {
    title: 'Twitter',
    link: 'https://twitter.com/wagwalking',
    icon: twitterLogo,
    iconHover: twitterLogoHover,
  },
  {
    title: 'Tiktok',
    link: 'https://www.tiktok.com/@wag',
    icon: tiktokLogo,
    iconHover: tiktokLogoHover,
  },
] as const;
