import React, { Fragment } from 'react';

import clsx from 'clsx';
import FullStarIcon from '../../assets/full-star.svg';
import EmptyStarIcon from '../../assets/empty-star.svg';
import HalfStarIcon from '../../assets/half-star.svg';
import BlackStarIcon from '../../assets/black-star.svg';

type Props = {
  count?: number;
  blackStar?: boolean;
  className?: string;
  showHalfStar?: boolean;
}

export const Stars = ({
  count = 5,
  blackStar = false,
  className,
  showHalfStar = false,
}: Props) => (
  <div className={clsx('flex h-fit', className)}>
    {blackStar ? (
      <img
        src={BlackStarIcon}
        alt="black-star-icon"
        width="16px"
        height="16px"
        className="mr-[3px]"
      />
    ) : Array.from(Array(Math.round(5)).keys()).map((key) => (
      <Fragment
        key={key}
      >
        {key === count && showHalfStar ? (
          <img
            src={HalfStarIcon}
            alt="half-star-icon"
            width="16px"
            height="16px"
            className="mr-[3px]"
          />
        ) : (
          <img
            src={key + 1 > count ? EmptyStarIcon : FullStarIcon}
            alt="star-icon"
            width="16px"
            height="16px"
            className="mr-[3px]"
          />
        )}
      </Fragment>
    ))}
  </div>
);
