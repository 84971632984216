import clsx from 'clsx';
import React from 'react';
import { legalLinks } from './data/legal-links';

type Props = {
  containerClassName?: string;
}

export const LegalLinks = ({
  containerClassName,
}: Props) => (
  <div
    className={clsx('flex flex-wrap', containerClassName)}
  >
    {legalLinks.map((link) => (
      <div
        key={link.title}
        className="w-[44%] md:w-[20%] mb-4 md:mb-0 md:text-center md:last:text-right"
      >
        <a
          href={link.url}
          className={clsx(
            'text-white w-fit md:w-content text-[12px] underline decoration-slate-400 font-[600]',
          )}
          aria-label={link.title}
        >
          {link.title}
        </a>
      </div>
    ))}
  </div>
);
