import React from 'react';
import { MenuIcon } from 'lucide-react';

import {
  Sheet, SheetTrigger, SheetContent, SheetHeader, SheetTitle, SheetContentProps,
} from '../../../../tw-components/ui/sheet';
import { AppInstall } from './AppInstall';
import { HeaderPromo } from './HeaderPromo';
import { menuItems } from './data/items';
import CollapsibleMenuItem from './CollapsibleMenuItem';
import { cn } from '../../../../utils/tailwind/cn';

type Props = {
  sheetContentProps?: SheetContentProps;
  menuIconClassName?: string;
}

export const Menu = ({ sheetContentProps, menuIconClassName }: Props) => (
  <Sheet>
    <SheetTrigger
      aria-label="menu-button"
    >
      <MenuIcon
        className={cn(
          'text-darkGray text-lg w-5 h-5', menuIconClassName,
        )}
      />
    </SheetTrigger>
    <SheetContent
      className="w-[86%] border-l-gray-200"
      {...sheetContentProps}
    >
      <SheetHeader>
        <SheetTitle>
          <HeaderPromo />
        </SheetTitle>
        <div
          className="flex flex-col pb-3"
        >
          {menuItems.map(
            (item) => (
              <CollapsibleMenuItem
                key={item.title}
                rootClassName="border-b border-darkGray/20 last:border-b-0"
                item={item}
              />
            ),
          )}
        </div>
      </SheetHeader>
      <AppInstall />
    </SheetContent>
  </Sheet>
);
