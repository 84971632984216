export const menuItems = [
  {
    title: 'Services',
    children: [
      {
        title: 'Walking',
        url: 'https://wagwalking.com/dog-walking',
      },
      {
        title: 'Drop-Ins',
        url: 'https://wagwalking.com/drop-ins',
      },
      {
        title: 'Sitting',
        url: 'https://wagwalking.com/dog-sitting',
      },
      {
        title: 'Boarding',
        url: 'https://wagwalking.com/dog-boarding',
      },
      {
        title: 'Training',
        url: 'https://wagwalking.com/dog-training',
      },
    ],
  },
  {
    title: 'Health & Wellness',
    children: [
      {
        title: 'Pet Insurance',
        url: 'https://wagwalking.com/wag-wellness/pet-insurance',
      },
      {
        title: 'Wag! Wellness Plans',
        url: 'https://wagwalking.com/wag-wellness/wellness-plans',
      },
      {
        title: 'Vet Chat',
        url: 'https://wagwalking.com/wag-health',
      },
    ],
  },
  {
    title: 'Resources',
    url: 'https://wagwalking.com/care',
  },
  {
    title: 'Help Center & Safety',
    url: 'https://support.wagwalking.com/?_gl=1*1xkw2wl*_gcl_aw*R0NMLjE2OTE1OTM5MzkuQ2owS0NRand6OGVtQmhEckFSSXNBTk5KalM3M3FqdUxlY1JQVGdIdzVzX3hVdmVadFFTYVNSeXA0ekZOTERRdlFhQnRyUUtfVGU4WkVENGFBckpCRUFMd193Y0I.*_gcl_au*MTIxNTcxNDU0MS4xNjkyMzAxNjA4',
  },
  {
    title: 'Deals',
    url: 'https://wagwalking.com/deals',
  },
  {
    title: 'Become a Caregiver',
    children: [
      {
        title: 'Become a Walker',
        url: 'https://wagwalking.com/dog-walker',
      },
      {
        title: 'Become a Sitter',
        url: 'https://wagwalking.com/dog-sitter',
      },
    ],
  },
];
