import clsx from 'clsx';
import React from 'react';

import { CollapsibleFooterMenu } from './CollapsibleFooter';
import { footerMenuItems } from './data/menu-links';

type Props = {
  containerClassName?: string;
}

type MenuLinkProps = {
  item: typeof footerMenuItems[number];
}

const MenuLink = ({ item }: MenuLinkProps) => (
  <div>
    <p className="text-white font-[700]">{item.title}</p>
    {item.children.map((child) => (
      <a
        key={child.title}
        className={clsx(
          'text-left first:mt-6 my-3 block w-fit text-[12px] text-white decoration-slate-400',
          'hover:underline hover:text-mySin hover:decoration-mySin',
          child?.title?.includes('View all')
            ? 'underline text-white'
            : 'no-underline text-white/80',
        )}
        href={child.url}
      >
        {child.title}
      </a>
    ))}
  </div>
);

export const MenuLinks = ({
  containerClassName,
}: Props) => (
  <>
    <div className={clsx('flex flex-col md:hidden', containerClassName)}>
      {footerMenuItems.map((menuItem) => (
        <CollapsibleFooterMenu
          key={menuItem.title}
          item={menuItem}
        />
      ),
      )}
    </div>
    <div className={clsx('hidden md:flex w-full justify-between', containerClassName)}>
      {footerMenuItems.map((menuItem, index) => {
        if (menuItem.title === 'Apply') return null;
        if (menuItem.title === 'Support') {
          return (
            <div
              key={menuItem.title}
              className="flex flex-col justify-between"
            >
              <MenuLink item={menuItem} />
              <MenuLink item={footerMenuItems[index + 1]} />
            </div>
          );
        }
        return (
          <MenuLink
            key={menuItem.title}
            item={menuItem}
          />
        );
      })}
    </div>
  </>
);
