import React from 'react';
import { cn } from '../../../../../../../utils/tailwind';

import appleLogo from '../../../../../assets/apple-logo.svg';

type Props = {
  className?: string;
  link?: string;
}

export const AppleStoreButton = ({
  className,
  link = 'https://wagwalking.app.link/mWi67ePDldb',
}: Props) => (
  <a
    href={link}
    aria-label="Download on the App Store"
    className={cn(
      'flex justify-center max-w-[134px] md:max-w-[150px] w-[44%] items-center',
      'border border-gray200/60 rounded-lg p-[5px]',
      'bg-black',
      className,
    )}
  >
    <img
      src={appleLogo}
      alt="apple-store"
      className="w-[16px] h-[20px] md:w-[20px] md:h-[24px] mr-2"
    />
    <div>
      <p className="text-[10px] text-white font-[500] !leading-3">Download on the</p>
      <p className="md:text-[16px] text-white font-[400] !leading-4">App Store</p>
    </div>
  </a>
);
