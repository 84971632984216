export const legalLinks = [
  {
    title: 'Privacy Policy',
    url: 'https://safety.wagwalking.com/privacy',
  },
  {
    title: 'Accessibility',
    url: 'https://safety.wagwalking.com/accessibility',
  },
  {
    title: 'Terms & Conditions',
    url: 'https://safety.wagwalking.com/terms',
  },
  {
    title: 'Transparency',
    url: 'https://safety.wagwalking.com/transparency-in-coverage',
  },
  {
    title: 'Notice to CA residents',
    url: 'https://safety.wagwalking.com/privacy',
  },
];
